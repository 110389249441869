import React, { useContext, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { findIndex } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinner as loaderIcon,
  faSearch,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faFrown } from '@fortawesome/free-regular-svg-icons';
import { FirebaseContext } from '../firebase';
import LoadUrl from './LoadUrl';
import { ALBUMDETAILS } from '../routes';

function Albums() {
  const firebase = useContext(FirebaseContext);
  const [albums, setAlbums] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [searchFilter, setSearchFilter] = useState('');

  const loadAlbums = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      let query = firebase.db.collection('albums').orderBy('release_date', 'desc');
      const querySnapshot = await query.get();
      // console.log(`docs:${querySnapshot.docs && querySnapshot.docs.length}`);
      const albums = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setIsLoading(false);
      setAlbums(albums);
    } catch (err) {
      setIsLoading(false);
      setError(err);
      console.log('Error getting documents: ', err);
    }
  }, [firebase.db]);

  useEffect(() => {
    loadAlbums();
  }, [loadAlbums]);

  const deleteAlbum = (albumId) => {
    firebase.db.collection('albums').doc(albumId).delete();
    setAlbums([...albums.filter((album) => album.id !== albumId)]);
  };

  const onUrlLoaded = (music) => {
    console.log(music);
    if (music.type === 'music.playlist') {
      setAlbums((currAlbums) => {
        const newAlbums = [...currAlbums];
        let albumIdx = findIndex(newAlbums, { id: music.id });
        if (albumIdx > -1) {
          console.log('found existing album');
          newAlbums[albumIdx] = music;
        } else {
          newAlbums.push(music);
        }
        return newAlbums;
      });
    }
  };

  const filteredAlbums = searchFilter
    ? albums.filter(
        (album) => album.id.search(searchFilter) > -1 || album.title.search(searchFilter) > -1
      )
    : albums;
  return (
    <>
      <LoadUrl onComplete={onUrlLoaded} />

      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <h2>Albums</h2>
        </div>
        <div className="col-12 col-md-6">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              value={searchFilter}
              onChange={(evt) => setSearchFilter(evt.target.value)}
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Artwork</th>
              <th scope="col">Title</th>
              <th scope="col">Release Date</th>
              <th scope="col">#</th>
              <th scope="col">Tracks (SoundCloud)</th>
              <th scope="col">Tracks (Loaded)</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={7} className="text-center">
                  <FontAwesomeIcon icon={loaderIcon} spin size={'5x'} />
                </td>
              </tr>
            )}
            {error && (
              <tr>
                <td colSpan={7}>
                  <div className="alert alert-danger">
                    <h4>Error while fetching data</h4>
                    <p>A problem occurred while trying to get data from the database:</p>
                    <pre>{error.toString()}</pre>
                  </div>
                </td>
              </tr>
            )}
            {!isLoading &&
              filteredAlbums &&
              filteredAlbums.map((album) => {
                const tracks_loaded = album.tracks ? album.tracks.length : 0;
                return (
                  <tr key={album.id}>
                    <td>
                      {album.artwork_url && (
                        <Link to={ALBUMDETAILS(album.id)}>
                          <img
                            src={album.artwork_url}
                            className="album-thumb"
                            alt="Album artwork"
                          />
                        </Link>
                      )}
                    </td>
                    <td>
                      {album.title}
                      <br />
                      <a href={album.permalink_url} target="_blank" rel="noopener noreferrer">
                        View on SoundCloud&nbsp;
                        <FontAwesomeIcon icon={faExternalLinkAlt} size={'xs'} />
                      </a>
                    </td>
                    <td>{moment(album.release_date).format('ll')}</td>
                    <td>{album.id}</td>
                    <td>{album.track_count}</td>
                    <td>
                      {tracks_loaded}
                      {album.track_count && parseInt(album.track_count) > tracks_loaded && (
                        <div className="tooltip-clv">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="text-warning ml-1"
                          />
                          <span className="tooltiptext">
                            Not all tracks have been loaded from SoundCloud
                          </span>
                        </div>
                      )}
                    </td>
                    <td>
                      <Link to={ALBUMDETAILS(album.id)} className="btn btn-link">
                        View
                      </Link>
                      <button className="btn btn-link" onClick={() => deleteAlbum(album.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            {!isLoading && !error && (!filteredAlbums || !filteredAlbums.length) && (
              <tr>
                <td colSpan={7} className="text-center">
                  <FontAwesomeIcon icon={faFrown} size={'2x'} />
                  <p>No data could be found. Use the box above to load data from SoundCloud.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Albums;
