import React, { useContext, useState } from 'react';
import axios from 'axios';
import { findIndex } from 'lodash';
import { FirebaseContext } from '../firebase';

const trackCountRegex = /set_type":"album".*?track_count":(\d*)/;

export default function LoadUrl({ onComplete, title, helpText }) {
  const [url, setUrl] = useState('');
  const [loadStatus, setLoadStatus] = useState();
  const firebase = useContext(FirebaseContext);

  /**
   * The list is a space-separated string, but for tags with spaces,
   * they are put in quotes.
   * @param tagList
   */
  function parseTags(tagList) {
    tagList = tagList.replace(/\\"/g, '"');
    const stage1Parts = tagList.split('"');
    const tags = stage1Parts.reduce((tags, currentTag) => {
      if (currentTag[0] === ' ' || currentTag[currentTag.length - 1] === ' ') {
        let items = currentTag.trim().split(' ');
        tags = tags.concat(items);
      } else {
        tags.push(currentTag);
      }
      return tags;
    }, []);

    return tags;
  }
  function parseMetadata(html) {
    const metadata = {};
    const regex = /<meta.*?(property|name)="(.*?)".*?>/gm;
    let m;

    while ((m = regex.exec(html)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      if (m.length > 2) {
        const valueMatch = m[0].match(/content="(.*?)"/);
        metadata[m[2]] = valueMatch && valueMatch.length ? valueMatch[1] : valueMatch;
      }
    }
    return metadata;
  }
  function getTrackUrls(html) {
    const trackUrls = [];
    const regex = /permalink_url":"(https:\/\/soundcloud.com\/[\w-]*?\/[\w-]*?)"/gm;
    let m;

    while ((m = regex.exec(html)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      if (m.length > 1) {
        trackUrls.push(m[1]);
      }
    }

    return trackUrls;
  }

  async function getUrlData(url) {
    try {
      const corsAnywhere = 'https://equip-cors.herokuapp.com/';
      const response = await axios.get(corsAnywhere + url);
      if (response && response.status === 200) {
        const html = response.data;

        const metadata = parseMetadata(html);
        const appUrl = metadata['al:ios:url']; // soundcloud://playlists:1067639557

        const releaseDateMatch = html.match(/release_date":"(.*?)"/);
        const displayDateMatch = html.match(/display_date":"(.*?)"/);
        const tagListMatch = html.match(/tag_list":"(.*?)",/);
        let tagList = tagListMatch && tagListMatch.length > 1 ? tagListMatch[1] : '';
        const music = {
          id: appUrl.substring(appUrl.lastIndexOf(':') + 1),
          type: metadata['og:type'] || '',
          title: metadata['og:title'] || '',
          description: metadata['og:description'] || '',
          artwork_url: metadata['og:image'] || '',
          permalink_url: metadata['og:url'] || '',
          release_date:
            releaseDateMatch && releaseDateMatch.length > 1
              ? releaseDateMatch[1]
              : releaseDateMatch,
          tag_list: tagList,
          tags: parseTags(tagList),
        };

        // Tracks often don't have a release date, but this
        // display date is what seems to be shown on the site
        if (!music.release_date && displayDateMatch && displayDateMatch.length > 1) {
          music.release_date = displayDateMatch[1];
        }

        if (music.type === 'music.playlist') {
          const slugMatch = url.match(/soundc.oud.com\/(.*)/);
          const slug = slugMatch && slugMatch.length > 1 ? slugMatch[1] : '';
          const regex = 'track_count":(\\d*)[^{}]*?"url":"\\/' + slug;
          const trackCountMatch = html.match(regex);
          if (trackCountMatch && trackCountMatch.length > 1) {
            music.track_count = parseInt(trackCountMatch[1]);
          }
          setLoadStatus('Fetching tracks...');
          const trackUrls = getTrackUrls(html);

          music.tracks = [];
          for (let url of trackUrls) {
            const track = await getUrlData(url);
            music.tracks.push(track);
          }
          //todo: aggregate playback_count from tracks?
        } else {
          const playbackCountMatch = html.match(/playback_count":(\d*)/);
          if (playbackCountMatch && playbackCountMatch.length > 1) {
            music.playback_count = parseInt(playbackCountMatch[1]);
          }
        }

        return music;
      }
    } catch (ex) {
      console.error(ex);
    }
    return null;
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    console.log(`submit:${url}`);
    setLoadStatus('Fetching music...');
    const album = await getUrlData(url);

    if (album.type === 'music.playlist') {
      setLoadStatus('Saving album...');
      const docRef = firebase.db.collection('albums').doc(album.id);

      // Get existing doc to merge tracks
      const doc = await docRef.get();
      if (doc.exists) {
        const mergedTracks = [...doc.data().tracks];
        for (let track of album.tracks) {
          let matchedTrackIdx = findIndex(mergedTracks, { id: track.id });
          if (matchedTrackIdx > -1) {
            mergedTracks[matchedTrackIdx] = track;
          } else {
            mergedTracks.push(track);
          }
        }
        album.tracks = mergedTracks;
      }

      await docRef.set(album, { merge: true });
    }
    setLoadStatus(null);
    setUrl('');
    if (onComplete) {
      onComplete(album);
    }
  };

  const defaultHelpText = (
    <>
      Copy and paste the URL of either an album/playlist or a track from SoundCloud into the box
      above click "Load" in order to load it into the app.
      <br />
      Note: When loading albums or playlists, only the first 5 tracks can be loaded automatically,
      so you will need to paste the URLs for any other tracks that are not loaded automatically.
    </>
  );
  return (
    <div className="bg-primary container load-url-container mt-3 px-5 py-3 text-light">
      <h2>{title || 'Load Album'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="loadUrlInput" className="sr-only">
            SoundCloud URL
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              id="loadUrlInput"
              placeholder="SoundCloud URL for album/track (e.g. https://soundcloud.com/clavie-radio/sets/2v8v41xxpgno)"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
            />
            <div className="input-group-append">
              <button className="btn btn-secondary" type="submit" id="loadUrlSubmit">
                Load
              </button>
            </div>
          </div>
          <small id="loadUrlHelpBlock" className="form-text text-muted">
            {helpText || defaultHelpText}
          </small>
        </div>
        {loadStatus ? <div>Loading: {loadStatus}</div> : null}
      </form>
    </div>
  );
}
