import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { take } from 'lodash';
import axios from 'axios';
import { FirebaseContext } from '../firebase';
import * as ROUTES from '../routes';
const corsAnywhere = 'https://equip-cors.herokuapp.com/';
const batchSize = 100;

export default function Notifications() {
  const firebase = useContext(FirebaseContext);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [wasValidated, setWasValidated] = useState(false);
  const [logs, setLogs] = useState([]);

  const handleButtonClick = () => {
    setWasValidated(true);
  };
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLogs([]);

    // get all push tokens from firebase
    const snapshot = await firebase.db.collection('pushtokens').get();
    const tokens = snapshot.docs.map((doc) => doc.id);

    const tokenCount = tokens.length;
    if (tokenCount > batchSize) {
      setLogs([
        `Found ${tokenCount} devices to send notification to.`,
        `Sending notifications in batches of ${batchSize}...`,
      ]);
    } else {
      setLogs([`Sending notifications to ${tokenCount} devices...`]);
    }

    // send notifications in batches
    let counter = 0;
    let successfulSends = 0;
    let allResultData = [];
    const tokensToDelete = [];
    while (counter < tokenCount) {
      const tokenBatch = take(tokens.slice(counter), batchSize);
      counter += tokenBatch.length;

      try {
        // send to expo
        const url = corsAnywhere + 'https://exp.host/--/api/v2/push/send';
        const response = await axios.post(
          url,
          {
            to: tokenBatch,
            title,
            body,
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        const results = response.data.data;
        allResultData = allResultData.concat(results);
        const successResults = results.filter((i) => i.status === 'ok');
        const sentCount = successResults.length;
        successfulSends += sentCount;

        // Log errors
        console.log(`Sent ${sentCount}/${tokenBatch.length}`);
        setLogs((prev) => [...prev, `Sent ${sentCount}/${tokenBatch.length}`]);

        // Keep track of invalid tokens that should be deleted
        if (sentCount !== tokenBatch.length) {
          const invalidResults = results.filter(
            (i) => i.status === 'error' && i.details && i.details.error === 'DeviceNotRegistered'
          );

          for (let invalidResult of invalidResults) {
            const match = invalidResult.message.match(/ExponentPushToken\[.*?\]/);
            if (match && match.length) {
              tokensToDelete.push(match[0]);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }

    // Save results to firebase collection: pushnotifications
    // --title, body, date, data, totalDevices, totalSent, totalErrors
    // Not using 'await' so that this runs asynchronously
    firebase.db.collection('pushnotifications').add({
      title,
      body,
      date: firebase.core.firestore.FieldValue.serverTimestamp(),
      data: allResultData,
      totalDevices: tokenCount,
      totalSent: successfulSends,
      totalErrors: tokensToDelete.length,
    });

    // Delete invalid tokens
    if (tokensToDelete && tokensToDelete.length) {
      setLogs((prev) => [...prev, `Deleting ${tokensToDelete.length} invalid device tokens`]);
      for (let token of tokensToDelete) {
        // not using await so that these happen asynchronously...I hope
        firebase.db.collection('pushtokens').doc(token).delete();
      }
    }

    setLogs((prev) => [...prev, `Finished sending notifications`]);
  };
  return (
    <div>
      <h2>
        Send Push Notification&nbsp;
        <small>
          (<Link to={ROUTES.NOTIFICATIONHISTORY}>View History</Link>)
        </small>
      </h2>
      <form onSubmit={handleSubmit} className={wasValidated ? 'was-validated' : null}>
        <div className="form-group">
          <label htmlFor="title" className="">
            Title
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="form-control"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <small id="emailHelp" className="form-text text-muted">
            (Optional) Shown in first line of notification.
            <br />
            Android: Shown immediately following the app name - both in bold.
            <br />
            iOS: Shown on its own as a bold, standalone title.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="body" className="">
            Message Body
          </label>
          <input
            type="text"
            name="body"
            id="body"
            className="form-control"
            value={body}
            onChange={(event) => setBody(event.target.value)}
            required
          />
          <small id="emailHelp" className="form-text text-muted">
            The message to display in the notification.
          </small>
          <div className="invalid-feedback">Must enter at least a message body.</div>
        </div>

        <button type="submit" className="btn btn-primary" onClick={handleButtonClick}>
          Send
        </button>

        {logs && !!logs.length && (
          <code>
            <ul className="list-unstyled my-3">
              {logs.map((log, idx) => (
                <li key={idx}>{log}</li>
              ))}
            </ul>
          </code>
        )}
      </form>
    </div>
  );
}
