import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Albums from './components/Albums';
import AlbumDetail from './components/AlbumDetail';
import Notifications from './components/Notifications';
import NotificationHistory from './components/NotificationHistory';
import Login from './components/Login';
import AuthUserContext from './authContext';

export const HOME = '/albums';
export const ALBUMS = '/albums';
export const ALBUMDETAILS = (id) => `/albums/${id}`;
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATIONHISTORY = '/notifications/history';
export const LOGIN = '/login';

const Routes = () => {
  const authUser = useContext(AuthUserContext);

  function PrivateRoute({ children, ...props }) {
    return (
      <Route
        {...props}
        render={({ location }) =>
          authUser.loggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: LOGIN,
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
  return (
    <Switch>
      <PrivateRoute path="/notifications/history">
        <NotificationHistory />
      </PrivateRoute>
      <PrivateRoute path="/notifications">
        <Notifications />
      </PrivateRoute>
      <PrivateRoute path="/albums/:id">
        <AlbumDetail />
      </PrivateRoute>
      <PrivateRoute path="/albums">
        <Albums />
      </PrivateRoute>
      <Route path="/login" component={Login} />
      <Redirect exact from="/" to="/albums" />
    </Switch>
  );
};

export default Routes;
