import React from 'react';
import app, { firestore } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    // Use a singleton so there is only ever one instance
    if (!!Firebase.instance) {
      return Firebase.instance;
    }

    app.initializeApp(config);
    // app.analytics();

    this.auth = app.auth();
    this.db = app.firestore();
    this.core = {
      firestore,
    };

    Firebase.instance = this;
    return this;
  }

  onAuthStateChange(callback) {
    return this.auth.onAuthStateChanged((user) => {
      if (user) {
        callback({
          loggedIn: true,
          user,
        });
      } else {
        callback({ loggedIn: false });
      }
    });
  }
}
export default Firebase;

export const FirebaseContext = React.createContext(null);
