import React, { useContext, useEffect, useState } from 'react';
import Firebase, { FirebaseContext } from './firebase';
import AuthUserContext from './authContext';
import './scss/App.scss';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import Navigation from './components/Navigation';

function FirebaseProvider({ children }) {
  return <FirebaseContext.Provider value={new Firebase()}>{children}</FirebaseContext.Provider>;
}
function AuthProvider({ children }) {
  const firebase = useContext(FirebaseContext);
  const [user, setUser] = useState({ loggedIn: false });

  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    };
  }, [firebase]);

  return <AuthUserContext.Provider value={user}>{children}</AuthUserContext.Provider>;
}
function App() {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <BrowserRouter>
          <Navigation />

          <main role="main" className="container py-3">
            <Routes />
          </main>

          <footer className="bg-primary text-light p-4" data-build="1">
            <div className="container">© {new Date().getFullYear()} Equip Digital</div>
          </footer>
        </BrowserRouter>
      </AuthProvider>
    </FirebaseProvider>
  );
}

export default App;
