import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../routes';
import { FirebaseContext } from '../firebase';
import AuthUserContext from '../authContext';

export default function Navigation() {
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);
  const handleLogout = () => {
    firebase.auth.signOut();
  };
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary">
      <span className="navbar-brand">
        <img src="/clavie_logo.png" alt="C'est la Vie logo" height="40" loading="lazy" />
        Admin
      </span>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink to={ROUTES.ALBUMS} className="nav-link" activeClassName="active">
              Albums
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={ROUTES.NOTIFICATIONS} className="nav-link" activeClassName="active">
              Push Notifications
            </NavLink>
          </li>

          {authUser && authUser.loggedIn && (
            <li className="nav-item">
              <button className="nav-link btn btn-link" onClick={handleLogout}>
                Logout
              </button>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}
