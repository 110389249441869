import React, { useContext, useState } from 'react';
import { FirebaseContext } from '../firebase';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import AuthUserContext from '../authContext';

export default function Login() {
  const history = useHistory();
  const location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };
  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [wasValidated, setWasValidated] = useState(false);
  const [error, setError] = useState();

  const handleButtonClick = () => {
    setWasValidated(true);
  };
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    firebase.auth
      .signInWithEmailAndPassword(username, password)
      .then(() => history.replace(from))
      .catch((error) => setError(error));
  };

  if (authUser && authUser.loggedIn) {
    return <Redirect to={from} />;
  }

  return (
    <div className="col-lg-6 offset-lg-3">
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className={wasValidated ? 'was-validated' : null}>
        <div className="form-group">
          <label htmlFor="username" className="">
            Username/Email
          </label>
          <input
            type="text"
            name="username"
            id="username"
            className="form-control"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            required
          />
          <div className="invalid-feedback">Must enter username.</div>
        </div>

        <div className="form-group">
          <label htmlFor="password" className="">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
          <div className="invalid-feedback">Must enter password.</div>
        </div>

        <button type="submit" className="btn btn-primary" onClick={handleButtonClick}>
          Login
        </button>

        {error && <div className="alert alert-danger">{error.toString()}</div>}
      </form>
    </div>
  );
}
