import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FirebaseContext } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner as loaderIcon } from '@fortawesome/free-solid-svg-icons';
import { faFrown } from '@fortawesome/free-regular-svg-icons';

export default function NotificationHistory() {
  const firebase = useContext(FirebaseContext);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const loadNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      let query = firebase.db.collection('pushnotifications').orderBy('date', 'desc').limit(100);
      const querySnapshot = await query.get();
      const notifications = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setIsLoading(false);
      setNotifications(notifications);
    } catch (err) {
      setIsLoading(false);
      setError(err);
      console.log('Error getting documents: ', err);
    }
  }, [firebase.db]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  return (
    <>
      <h2 className="mt-3">Past Notifications</h2>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Content</th>
              <th scope="col">Total Sent</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={3} className="text-center">
                  <FontAwesomeIcon icon={loaderIcon} spin size={'5x'} />
                </td>
              </tr>
            )}
            {error && (
              <tr>
                <td colSpan={3}>
                  <div className="alert alert-danger">
                    <h4>Error while fetching data</h4>
                    <p>A problem occurred while trying to get data from the database:</p>
                    <pre>{error.toString()}</pre>
                  </div>
                </td>
              </tr>
            )}
            {!isLoading &&
              notifications &&
              notifications.map((notification) => {
                return (
                  <tr key={notification.id}>
                    <td>{moment(notification.date.toDate()).format('lll')}</td>
                    <td>
                      {notification.title && <h6>{notification.title}</h6>}
                      {notification.body}
                    </td>
                    <td>{notification.totalSent}</td>
                  </tr>
                );
              })}
            {!isLoading && !error && (!notifications || !notifications.length) && (
              <tr>
                <td colSpan={6} className="text-center">
                  <FontAwesomeIcon icon={faFrown} size={'2x'} />
                  <p>No data could be found.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
