import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { FirebaseContext } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { findIndex, unescape } from 'lodash';
import LoadUrl from './LoadUrl';

export default function AlbumDetail() {
  const firebase = useContext(FirebaseContext);
  const [album, setAlbum] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const deleteTrack = async (trackId) => {
    const newTracks = album.tracks.filter((track) => track.id !== trackId);

    // Save to firestore
    let docRef = firebase.db.collection('albums').doc(album.id);
    await docRef.update({ tracks: newTracks });

    // Update state/UI
    setAlbum({
      ...album,
      tracks: newTracks,
    });
  };
  const onTrackParsed = async (music) => {
    if (music && music.type === 'music.song') {
      const newTracks = [...album.tracks];
      let matchedTrackIdx = findIndex(newTracks, { id: music.id });
      if (matchedTrackIdx > -1) {
        newTracks[matchedTrackIdx] = music;
      } else {
        newTracks.push(music);
      }

      // save to firestore
      let docRef = firebase.db.collection('albums').doc(album.id);
      await docRef.update({ tracks: newTracks });

      // Update state/UI
      setAlbum({
        ...album,
        tracks: newTracks,
      });
    }
  };
  const loadAlbum = useCallback(
    async (id) => {
      try {
        let docRef = firebase.db.collection('albums').doc(id);
        const doc = await docRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc.data());
          setAlbum({
            ...doc.data(),
            id: doc.id,
          });
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        // todo: deal with this
        console.log('Error getting documents: ', err);
      }
    },
    [firebase.db]
  );

  useEffect(() => {
    loadAlbum(id);
  }, [loadAlbum, id]);
  return (
    <>
      <h2 className="my-4">{album.title || <Skeleton />}</h2>

      {isLoading ? (
        <Skeleton count={7} />
      ) : (
        <table className="table table-borderless table-striped">
          <tbody>
            <tr>
              <td className="album-label">ID</td>
              <td className="album-value">{album.id}</td>
            </tr>
            <tr>
              <td className="album-label">Title</td>
              <td className="album-value">{album.title}</td>
            </tr>
            <tr>
              <td className="album-label">Description</td>
              <td className="album-value">{album.description}</td>
            </tr>
            <tr>
              <td className="album-label">Release Date</td>
              <td className="album-value">{album.release_date}</td>
            </tr>
            <tr>
              <td className="album-label">SoundCloud URL</td>
              <td className="album-value">
                <a href={album.permalink_url} target="_blank" rel="noopener noreferrer">
                  {album.permalink_url}&nbsp;
                  <FontAwesomeIcon icon={faExternalLinkAlt} size={'xs'} />
                </a>
              </td>
            </tr>
            <tr>
              <td className="album-label">Tags</td>
              <td className="album-value">
                {album.tags &&
                  album.tags.length &&
                  album.tags
                    .filter((t) => t)
                    .map((tag) => (
                      <span
                        className="badge badge-pill badge-dark mx-1"
                        key={encodeURIComponent(tag)}>
                        #{tag}
                      </span>
                    ))}
              </td>
            </tr>
            <tr>
              <td className="album-label">Total Tracks</td>
              <td className="album-value">{album.track_count}</td>
            </tr>
          </tbody>
        </table>
      )}

      {!isLoading && (
        <>
          <h3 className="my-4">Tracks ({(album.tracks && album.tracks.length) || 0})</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Release Date</th>
                <th>ID</th>
                <th>Link</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {album.tracks && album.tracks.length ? (
                album.tracks.map((track, idx) => (
                  <tr key={track.id}>
                    <td>{idx + 1}</td>
                    <td>{unescape(track.title)}</td>
                    <td>{track.release_date ? moment(track.release_date).format('ll') : ''}</td>
                    <td>{track.id}</td>
                    <td>
                      <a href={track.permalink_url} target="_blank" rel="noopener noreferrer">
                        SoundCloud&nbsp;
                        <FontAwesomeIcon icon={faExternalLinkAlt} size={'xs'} />
                      </a>
                    </td>
                    <td>
                      <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => deleteTrack(track.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No tracks loaded
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <LoadUrl
            title="Load Track"
            helpText="Paste URL to track that you want to add to this album."
            onComplete={onTrackParsed}
          />
        </>
      )}
    </>
  );
}
